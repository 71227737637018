.reply {
    max-width: 600px;
    padding-right: 15px;
    padding-bottom: 1rem;
}

.replyButtons {
    font-size: 80%;
    color: var(--theme-grey);
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 1rem;
    vertical-align: middle;
}

.replyButtons > * {
    padding-top: .4rem;
    padding-bottom: .8rem;
    cursor: pointer;
}

.skeleton .input {
    background-color: var(--theme-grey);
    width: 100%;
    border-radius: .4rem;
    height: 115px;
    margin-bottom: 1rem;
}

.skeleton .button {
    background-color: var(--theme-grey);
    border-radius: .4rem;
    margin-top: .25rem;
    width: 71px;
    height: 38px;
}

.noTopLeftRadius textarea {
    border-top-left-radius: 0;
}

.reply textarea {
    margin-top: -1px;
}

.reply .text {
    margin-top: -1px;
    height: auto;
}