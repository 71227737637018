.upvote {
    fill: #a5a5a5;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

.boost {
    fill: var(--theme-clickToContextColor);
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

@media (hover: hover) {
    .upvote:hover {
        fill: var(--hover-fill) !important;
        filter: var(--hover-filter) !important;
    }

    .boost:hover {
        fill: var(--hover-fill) !important;
        filter: var(--hover-filter) !important;
    }
}

.boost.boosted {
    fill: var(--fill);
    filter: var(--filter);
}

.upvoteWrapper {
    position: relative;
    padding-right: .2rem;
    padding-left: .2rem;
    margin-left: -.4rem;
}

.noSelfTips {
    visibility: hidden;
}

.upvoteWrapper:not(.noSelfTips):hover {
    cursor: pointer;
}

.upvote.voted {
    fill: var(--fill);
    filter: var(--filter);
}

.cover {
    background: var(--bs-body-bg);
    width: 100%;
    overflow: hidden;
    mix-blend-mode: color;
    position: absolute;
    left: 4px;
    width: 17px;
}

.pending {
    fill: var(--hover-fill);
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.25s;
    animation-direction: alternate;
}

@keyframes pulse {
	0% {
		fill: #a5a5a5;
	}
}
