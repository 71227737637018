.item {
    align-items: flex-start;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
}

.upvote {
    margin-top: 9px;
    padding-right: 0.2rem;
}

.pin {
    fill: #a5a5a5;
    margin-left: .2rem;
    margin-right: .3rem;
    flex-shrink: 0;
}

.dontLike {
    fill: #a5a5a5;
    margin-right: .35rem;
    padding: 2px;
    margin-left: 1px;
    margin-top: 9px;
    cursor: pointer;
}

.text {
    margin-top: .1rem;
    padding-right: 15px;
}

.edit {
    display: inline-block;
    cursor: pointer;
}

.op {
    margin-top: -1px;
    vertical-align: text-top;
}

.collapsed .hunk {
    margin-bottom: .5rem;
}

.collapsed .text,
.collapsed .children {
    display: none;
}

.collapsed .upvote, .collapsed .dontLike {
    visibility: hidden;
    height: 0;
}

.collapser {
    cursor: pointer;
    fill: var(--theme-grey);
    width: 45px;
    margin-left: auto;
    user-select: none;
}

.children {
    margin-top: 0;
    margin-left: 27px;
}

.comments {
    margin-left: -.75rem;
}

@media screen and (min-width: 768px) {

    .comments {
        margin-left: .75rem;
    }
  }

.skeleton .hunk {
    width: 100%;
}

.skeleton .text {
    height: 80px;
    border-radius: .4rem;
    margin-right: 15px;
}

.skeleton .reply {
    width: 45px;
    height: 10px;
    border-radius: .2rem;
}

.replyPadder {
    padding: .25rem 0;
    padding-bottom: .5rem;
}

.replyContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.comment {
    border-radius: .4rem;
    padding-top: .5rem;
    padding-left: .7rem;
    background-color: var(--theme-commentBg);
}

.bountyIcon {
    margin-left: 5px;
    margin-right: 5px;
}

.hunk {
    margin-bottom: 0;
    margin-top: 0.15rem;
}

.comment:not(:last-of-type) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.comment:not(:first-of-type) {
    padding-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.comment:has(.comment) + .comment{
    padding-top: .5rem;
}