div.fullScreenNavContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

img.fullScreen {
    cursor: zoom-out !important;
    max-height: 100%;
    max-width: 100vw;
    min-width: 0;
    min-height: 0;
    align-self: center;
    justify-self: center;
    user-select: none;
}

.fullScreenContainer {
    --bs-columns: 1;
    --bs-rows: 1;
    display: grid;
    width: 100%;
    height: 100%;
}

div.fullScreenNav:hover > svg {
    background-color: rgba(0, 0, 0, .5);
}

div.fullScreenNav  {
    cursor: pointer;
    pointer-events: auto;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
}

div.fullScreenNav.left {
    justify-content: flex-start;
}

div.fullScreenNav.right {
    justify-content: flex-end;
}

div.fullScreenNav > svg {
    border-radius: 50%;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.7);
    fill: white;
    max-height: 34px;
    max-width: 34px;
    padding: 0.35rem;
    margin: .75rem;
}