.searchSection {
    background-color: var(--bs-body-bg);
    z-index: 1;
}

.searchContainer {
    position: relative;
    padding-bottom: 0.5rem;
}

.search {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    left: auto !important;
}

form>.active {
    display: flex;
    pointer-events: auto;
    flex-flow: row nowrap;
    align-items: center;
}

form>.active :global(.input-group) {
    flex-flow: nowrap;
}