.brand {
    padding: 0;
    margin-right: 0;
}

.brand svg {
    fill: var(--theme-brandColor);
    filter: drop-shadow(0 0 6px var(--bs-primary));
}

.postAnon {
    border-width: 2px;
}

.postAnon svg {
    fill: var(--bs-grey-darkmode);
}

.postAnon:hover, .postAnon:active, .postAnon:focus-visible {
    color: var(--bs-white) !important;
}

.postAnon:hover svg, .postAnon:active svg, .postAnon:focus-visible svg {
    fill: var(--bs-white);
}

.navLinkButton {
    border: 2px solid;
    padding: 0.2rem .9rem !important;
    border-radius: .4rem;
}

.navLink {
    padding: 0.25rem 1rem;
}

.navbar {
    padding: .5rem 0;
    background-color: transparent !important;
}

.navLink.active svg {
    fill: var(--theme-navLinkActive);
}

.hide {
    display: none;
}

.dropdown svg {
    vertical-align: text-top;
}

.jobIndicator {
    position: absolute;
    padding: .25rem;
    background-color: var(--bs-primary);
    top: 3px;
    right: 0px;
    border: 1px solid var(--bs-body-bg);
}

.notification {
    position: absolute;
    padding: .25rem;
    background-color: var(--bs-danger);
    top: 3px;
    right: 8px;
    border: 1px solid var(--bs-body-bg);
}

.navbarNav {
    width: 100%;
    align-items: center;
    height: 39px;
    align-content: center;
}

.price {
    margin-top: 2px;
    white-space: nowrap;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.price > div {
    font-size: min(3.5cqw, 0.9rem);
}

.dropdown {
    padding: 0;
}

.dropdown>a {
    padding-left: 0 !important;
}

.dropdown>button {
    padding-left: 0 !important;
}

.sticky {
    position: fixed;
    border-bottom: 1px solid var(--theme-toolbarActive);
    width: 100vw;
    top: -1px;
    padding-top: 1px;
    background-color: var(--bs-body-bg);
    z-index: 1000;
}