.usernameForm {
    width: 300px;
    margin-bottom: 0;
}

.nav {
    margin: 1rem 0;
    justify-content: start;
    font-size: 110%;
}

.nav :global .nav-link {
    padding-left: 0;
}

.nav :global .nav-item:not(:first-child) {
    margin-left: 1rem;
}

.nav :global .active {
    border-bottom: 2px solid var(--bs-primary);
}

.userimg {
    clip-path: polygon(0% 0%, 83% 0%, 100% 100%, 17% 100%);
    object-fit: cover;
}

.username {
    font-weight: bold;
    font-size: 150%;
    line-height: 1.25rem;
}

.social {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
}

.hidden {
    position: relative;
    padding: .5rem;
    padding-bottom: 1.5rem;
    border: 1px solid var(--bs-secondary);
    border-radius: .25rem;
    margin-bottom: 1rem;
}

.hidden::after {
    content: 'only visible to you';
    position: absolute;
    font-size: 75%;
    bottom: 0;
    left: 0;
    padding: .05rem .5rem;
    background-color: var(--bs-secondary);
    color: var(--bs-light);
    border-radius: 0 .25rem 0 0;
}