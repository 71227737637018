.tableContainer {
    width: 100%;
    max-height: 60svh;
    overflow-y: auto;
    padding-right: 1em;
    font-size: x-small;
    font-family: monospace;
    color: var(--theme-grey) !important; /* .text-muted */
}

@media screen and (min-width: 768px) {
    .tableContainer {
        max-height: 70svh;
    }

    .embedded {
        max-height: 30svh;
    }
}

.tableRow {
    font-family: monospace;
    color: var(--theme-grey) !important; /* .text-muted */
}

.timestamp {
    vertical-align: top;
    text-wrap: nowrap;
    justify-self: first baseline;
}

.wallet {
    vertical-align: top;
    font-weight: bold;
}

.level {
    font-weight: bold;
    vertical-align: top;
    text-transform: uppercase;
    padding-right: 0.5em;
}
