.status {
    font-family: 'lightning';
    font-size: 96px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}

.smaller {
    font-size: 48px;
}

.describe {
    font-size: 24px;
}