.title {
    white-space: normal;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: .15rem;
}

.summaryText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.notification {
    position: absolute;
    padding: 3px;
    background-color: var(--bs-info);
    top: -3px;
    right: -4px;
    border: 1px solid var(--bs-body-bg);
}

.icon {
    display: inline-block;
}

a.title:visited {
    color: var(--theme-grey) !important;
}

.upvote {
    margin-top: 3px;
    padding-right: .2rem;
}

.link {
    font-size: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 128px;
    max-width: fit-content;
    margin-bottom: .15rem;
}

.badge {
    vertical-align: middle;
    margin-top: -1px;
    margin-left: 0.1rem;
}

.newComment {
    color: var(--theme-grey) !important;
    background: var(--theme-clickToContextColor) !important;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: 0.1rem;
}

.pin {
    fill: #a5a5a5;
    margin-right: .4rem;
    margin-left: -.2rem;
    flex-shrink: 0;
}

.ad {
    fill: #a5a5a5ac;
    margin-right: .4rem;
    margin-left: -.2rem;
    flex-shrink: 0;
}

.dontLike {
    fill: #a5a5a5;
    margin-right: .35rem;
    margin-left: -.2rem;
    flex-shrink: 0;
    cursor: pointer;
}

.case {
    fill: #a5a5a5;
    margin-right: .2rem;
    margin-top: .2rem;
    padding: 0 2px;
}

.linkSmall {
    width: 128px;
    display: inline-block;
    vertical-align: text-top;
}

a.link:visited {
    color: var(--theme-linkVisited) !important;
}

.other {
    font-size: 80%;
    color: var(--theme-grey);
    vertical-align: text-top;
    margin-bottom: .125rem;
    word-break: break-word;
}

.other svg {
    vertical-align: text-top;
}

.other .dropdown svg {
    margin-top: -1px;
}

.other :global(.dropdown-item) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.item {
    display: flex;
    justify-content: flex-start;
    min-width: 0;
    padding-top: .5rem;
}

.item .companyImage {
    border-radius: 100%;
    align-self: center;
    margin-right: 0.5rem;
    margin-left: 0.3rem;
}

.itemDead {
    pointer-events: none;
    opacity: .5;
}

.item .cover {
    top: 4px;
}

.hunk {
    min-width: 0;
    width: 100%;
    line-height: 1.06rem;
}

.bountyIcon {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -2px;
}

.grid {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
}

.details {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    line-height: 1.25;
    row-gap: 0.75rem;
}

/* .itemJob .hunk {
    align-self: center;
}

.itemJob .rank {
    align-self: center;
} */

.main {
    display: flex;
    align-items: baseline;
    word-break: break-word;
}

.children {
    margin-left: 27px;
    padding-top: .5rem;
}

.rank {
    font-weight: 600;
    margin-top: .25rem;
    display: flex;
    color: var(--theme-grey);
    font-size: 90%;
    margin-right: .75rem;
}

.skeleton .other {
    height: 14px;
    align-items: center;
}

.skeleton .title {
    background-color: var(--theme-grey);
    width: 500px;
    border-radius: .4rem;
}

.skeleton .title::after {
    content: "\00a0";
}

.skeleton .name {
    background-color: var(--theme-grey);
    width: 100px;
    border-radius: .4rem;
    height: 17px;
    margin: 0;
}

.skeleton .hunk {
    margin: 2px 0;
}

.skeleton .link {
    background-color: var(--theme-grey);
    width: 800px;
    border-radius: .3rem;
    line-height: .8rem;
    max-width: 256px;
}

.skeleton .link::after {
    content: "\00a0";
}

.skeleton .otherItem {
    display: inline-flex;
    width: 42px;
    height: 70%;
    border-radius: .27rem;
    background-color: var(--theme-grey);
    margin-right: .5rem;
}

.skeleton .otherItemLonger {
    width: 60px;
}
