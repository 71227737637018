.comments {
    margin-top: .5rem;
}

.other {
    font-size: 80%;
    color: var(--theme-grey);
}

@media only screen and (max-width: 600px) {
    .comments {
        margin-top: .5rem;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.fullItemContainer {
    margin-bottom: .5rem;
    padding-right: 15px;
}

.fullItemContainer:empty {
    margin: 0;
}