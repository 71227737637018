.page {
    width: 100%;
    display: flex;
    flex-flow: column;
    height: 100%;
    align-items: center;
    margin: auto;
}

.content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 740px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 1.5rem 0;
    flex-direction: column;
}

.contain {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
}

.content form {
    width: 100%;
}