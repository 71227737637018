.connect {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    opacity: 0.7;
}

.connect:hover {
    opacity: 1;
}

.contrastLink {
  color: var(--bs-body-color);
}
.contrastLink:hover {
    color: var(--bs-body-color);
}
.contrastLink svg {
    fill: var(--bs-body-color);
}

.version {
    margin: auto;
    font-size: 75%;
    margin-top: .15rem;
    color: var(--theme-grey) !important;
}
