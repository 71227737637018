.noTopLeftRadius textarea {
    border-top-left-radius: 0;
}

textarea.passwordInput {
    resize: none;
}

.markdownInput textarea {
    margin-top: -1px;
    font-size: 94%;
    line-height: 140%;
}

@media screen and (min-width: 767px) {
    .markdownInput textarea {
        line-height: 130%;
    }
}

.markdownInput .text {
    margin-top: -1px;
    height: auto;
}

.dragOver {
    box-shadow: 0 0 10px var(--bs-info);
}

.appendButton {
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.clearButton {
    background-color: var(--theme-inputBg);
    border: 1px solid var(--theme-borderColor);
    padding: 0rem 0.5rem;
    display: flex;
    align-items: center;
}

.clearButton:hover, .clearButton:active {
    background-color: var(--theme-inputBg) !important;
    border: 1px solid var(--theme-borderColor) !important;
    border-left: 0 !important;
}
.clearButton.isInvalid {
    border-color: #c03221;
}

/* https://github.com/react-bootstrap/react-bootstrap/issues/5475 */
.suggestionsMenu {
    opacity: 1 !important;
    pointer-events: unset !important;
}

.previewTab {
    padding-top: .2rem;
    padding-bottom: .3rem;
}

.pending {
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 0.66s;
    animation-direction: alternate;
}

@keyframes pulse {
	0% {
		opacity: 42%;
	}
}

div.qr {
    display: grid;
}

div.qr>svg {
    justify-self: center;
    width: 100%;
    height: auto;
    padding: 1rem;
    background-color: white;
}
