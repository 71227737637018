.receipt {
    background-color: var(--theme-inputBg);
    max-width: 300px;
    margin: auto;
    table-layout: auto;
    width: 100%;
}

.feeButton {
    display: flex;
    align-items: center;
}

.feeButton small {
    font-weight: 400;
}

.receipt td {
    padding: .25rem .1rem;
    background-color: var(--theme-inputBg);
    color: var(--bs-body-color);
}

.receipt tfoot {
    border-top: 2px solid var(--theme-borderColor) !important;
}