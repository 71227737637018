.pollButton {
    margin-top: .25rem;
    display: block;
    border: 2px solid var(--bs-info);
    border-radius: 2rem;
    width: 100%;
    max-width: 600px;
    padding: .4rem 1.1rem;
    line-height: 1rem;
    text-transform: uppercase;
}

.pollBox {
    padding-top: .5rem;
    padding-right: 15px;
    width: 100%;
    max-width: 600px;
}

.pollResult {
    text-transform: uppercase;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-top: .25rem;
    display: flex;
    border-radius: .4rem;
    padding: .25rem .05rem;
}

.pollProgress {
    content: '\A';
    border-radius: .4rem 0rem 0rem .4rem;
    position: absolute;
    background: var(--theme-clickToContextColor);
    top: 0;
    bottom: 0;
    left: 0;
}

.pollResult .pollOption {
    align-self: center;
    margin-left: .5rem;
    display: flex;
    line-height: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5rem;
}