.toastContainer {
  transform: translate3d(0, 0, 0);
  display: grid;
}

.toast {
  font-size: small;
  width: fit-content;
  justify-self: right;
  color: #fff;
  bottom: -100%;
  border-width: 1px;
  border-style: solid;
  text-overflow: ellipsis;
  animation: slide ease-out 0.2s;
}

@keyframes slide {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%)
  }
}

.success {
  border-color: var(--bs-success-border-subtle);
}

.danger {
  border-color: var(--bs-danger-border-subtle);
}

.warning {
  border-color: var(--bs-warning-border-subtle);
}

.toastClose {
  color: #fff;
  font-family: "lightning";
  font-size: 150%;
  line-height: 1rem;
  margin-bottom: -0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.progressBar {
  width: 0;
  height: 5px;
  filter: brightness(66%);
  animation-name: progressBar;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  /* animation-duration set via JS */
}

.progressBar.success {
  background-color: var(--bs-success);
}

.progressBar.danger {
  background-color: var(--bs-danger);
}

.progressBar.warning {
  background-color: var(--bs-warning);
}



@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.toastClose:hover {
  opacity: 0.7;
}
